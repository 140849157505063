import { ChangeDetectionStrategy, Component } from '@angular/core'
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-public-header',
    templateUrl: './public-header.component.html',
    styles: [`
      .logo {height: 61px;}
      .princeton {height: 63px;}

      @media (max-width: 500px) {
         .logo {height: 40px;}
         .princeton {height: 45px;}
      }
   `],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink]
})
export class PublicHeaderComponent {

   constructor() { }

   /*
   * private methods
   * */

   /*
   * public methods
   * */
}
