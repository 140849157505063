import { RegistrationProduct } from '../../src/app/shared/services/registrations.service';

export interface RouteModel {
   baseUri: string
   title: string
   uri: string
}

const routes = {
   content: {
      faq: {
         title: 'FAQ',
         baseUri: 'faq',
         uri: 'faq'
      } as RouteModel,
      title: 'Courses',
      baseUri: 'courses',
      uri: 'courses'
   },
   completeRegistration: {
      title: 'Compete Registration',
      baseUri: 'complete-registration',
      uri: 'complete-registration'
   } as RouteModel,
   member: {
      account: {
         profile: {
            title: 'Profile',
            baseUri: 'profile',
            uri: 'profile'
         } as RouteModel,
         title: 'Account',
         baseUri: 'account',
         uri: 'account'
      },
      setPassword: {
         title: 'Set Password',
         baseUri: 'set-password',
         uri: 'set-password'
      } as RouteModel,
      siteNotifications: {
         title: 'Notifications',
         baseUri: 'site-notifications',
         uri: 'site-notifications'
      } as RouteModel,
      title: 'Member Access',
      baseUri: 'member',
      uri: 'member'
   },
   registration: {
      title: 'Registration',
      baseUri: 'registration',
      uri: 'registration'
   },
   registrationLanding: {
      title: 'registration',
      baseUri: 'registration-landing',
      uri: 'registration-landing'
   },
   signUp: {
      title: 'Create Account',
      baseUri: 'sign-up',
      uri: 'sign-up'
   },
   title: 'College Entrance Academy',
   uri: ''
}

export const environment = {
   production: false,
   appStore: {
      apple: 'https://apps.apple.com/us/app/southwestern-lead/id6478800540 ',
      googlePlay: 'https://play.google.com/store/apps/details?id=com.southwestern.lead'
   },
   baseUri: 'https://southwestern-learning-web-portal-test-as.azurewebsites.net',
   brightcove: {
      accountId: 753829919001,
      experiences: {
         dashboard: '65e8c10567acf13ae75b3dfd',
         faq: '661436a3fd8b4685703d56bf',
         meetTheMentorsUrl: 'https://site-28869320.bcvp0rtal.com/',
         personalitiesPortalUrl: 'https://site-76420120.bcvp0rtal.com'
      },
      playerId: 'KrH874wOd'
   },
   contactUsUri: 'https://southwesternadvantage.com/about/',
   dealerApiUri: 'https://testportal.advantage4dealers.com/api/dealer',
   playerUri: 'player',
   publicCdn: {
      uri: 'https://swfclearningprod.blob.core.windows.net/cea',
      moduleCourseImagesFolder: 'course-images'
   },
   resourceMenuUris: {
      academicResources: 'https://www.advantage4teens.com',
      contest: 'https://sites.google.com/view/southwesternlead',
      privacyPolicy: 'https://southwesternadvantage.com/privacy-policy/'
   },
   routes,
   tprSSOEndpoint: 'https://secure.princetonreview.com/ltisso/launch',
   userPrincipalIdHeaderName: 'UserPrincipalId-29239E59E0924FC4',
   oAuthClientIdHeaderName: 'Client-Id-29239E59E0924FC4',
   oAuthClientSecretHeaderName: 'Client-Secret-29239E59E0924FC4',
   oAuthClientIdentifier: '9922177285349731',
   oAuthClientSecret: 'feaaec1a3f79435e82ae7bf712299850',
   serviceLayerClientIdHeaderName: 'Client-Id-EE8289B486034E60',
   serviceLayerClientId: '1204683634232208',
   serviceLayerClientSecretHeaderName: 'Client-Secret-EE8289B486034E60',
   serviceLayerClientSecret: 'e91ee2fd0f634a28aa43e0223c3864bf',
   registrationProducts: [
      {
         productSourceId: '' /* ALL Sites */,
         showLandingIfSingle: false,
         registrationSites: [
            {
               uri: 'https://localhost:44350/registration',
               uriSuffix: '?token=',
               appendToken: true,
               appendSubscriptionId: true,
               logoUri:
                  'https://swfclearningprod.blob.core.windows.net/cea/course-images/CED.png?v=240611',
               logoAlt: 'College Entrance Advantage Digital',
               description:
                  'College Entrance Advantage Digital - The Program that helps you conquer the SAT and ACT.'
            },
            {
               uri: 'https://localhost:44350/registration',
               uriSuffix: '?token=',
               appendToken: true,
               appendSubscriptionId: true,
               logoUri:
                  'https://swfclearningprod.blob.core.windows.net/cea/course-images/APD.png?v=240611',
               logoAlt: 'Advanced Placement Digital',
               description:
                  'Advanced Placement Digital - Everything you need for top AP scores.'
            }
         ]
      } as RegistrationProduct,
      {
         productSourceId: 'CED' /* College Entrance Advantage Digital */,
         showLandingIfSingle: false,
         registrationSites: [
            {
               uri: 'https://localhost:44350/registration',
               uriSuffix: '?token=',
               appendToken: true,
               appendSubscriptionId: true,
               logoUri:
                  'https://swfclearningprod.blob.core.windows.net/cea/course-images/CED.png?v=240611',
               logoAlt: 'College Entrance Advantage Digital',
               description:
                  'College Entrance Advantage Digital - The Program that helps you conquer the SAT and ACT.'
            }
         ]
      } as RegistrationProduct,
      {
         productSourceId: 'APD' /* Advanced Placement Digital */,
         showLandingIfSingle: false,
         registrationSites: [
            {
               uri: 'https://localhost:44350/registration',
               uriSuffix: '?token=',
               appendToken: true,
               appendSubscriptionId: true,
               logoUri:
                  'https://swfclearningprod.blob.core.windows.net/cea/course-images/APD.png?v=240611',
               logoAlt: 'Advanced Placement Digital',
               description:
                  'Advanced Placement Digital - Everything you need for top AP scores.'
            }
         ]
      } as RegistrationProduct
   ] as RegistrationProduct[]
};
