import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core'
import { NgIf, AsyncPipe } from '@angular/common'
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { filter, map, Observable, Subject, switchMap, take, takeUntil } from 'rxjs'
import { LeadSection, LeadSections, LeadSection_ } from '@appShared/services/lookup/lead-section'
import { AppFacade } from '@appShared/services/app.facade'
import { IUser } from '@appShared/interfaces/[Interface-based]/user.interface'
import { AuthService, CommonService, GlobalPubSubService, HybridWebviewInteropService } from '@appShared/services'
import { SiteFooterComponent } from '@appShared/components/site-footer/site-footer.component'
import { InternalHeaderComponent } from '@appShared/components/internal-header/internal-header.component'
import { PublicHeaderComponent } from '@appShared/components/public-header/public-header.component'

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, PublicHeaderComponent, InternalHeaderComponent, RouterOutlet, SiteFooterComponent, AsyncPipe]
})
export class ShellComponent implements AfterViewInit {
   //userProfile$: Observable<IProfile>
   title = 'SWFC - LEAD'
   user$: Observable<IUser>
   currentYear = new Date().getFullYear()
   sidebarToggle: HTMLElement
   private _ngDestroyed$ = new Subject()
   private _initialLeadSection = LeadSections.find(section => section.code === LeadSection_.Home)
   leadSection = { ...this._initialLeadSection }

   isDotNetInteroperable$: Observable<boolean>

   constructor(
      private _router: Router,
      activatedRoute: ActivatedRoute,
      appFacade: AppFacade,
      hybridService: HybridWebviewInteropService,
      private _globalPubSubService: GlobalPubSubService,
      private _commonService: CommonService,
      private _authService: AuthService
   ) {
      this.user$ = appFacade.user$
      this.isDotNetInteroperable$ = hybridService.isDotNetInteroperable$

      //this._globalPubSubService.subscribe("angular.learning.say-hi", this.sayHi, this)
      //this._globalPubSubService.subscribe("angular.learning.retrieve-user-info", this.getMeUserInfo, this)
      //this._globalPubSubService.subscribe("angular.learning.trigger-and-emit-something", this.triggerAndEmit, this)


      this._router.events
         .pipe(
            takeUntil(this._ngDestroyed$),
            filter(routeEvent => routeEvent instanceof NavigationEnd),
            map(() => activatedRoute),
            map(route => {
               while (route.firstChild) route = route.firstChild
               return route
            }),
            switchMap(firstChild => {
               return firstChild?.data
            }),
            map((data: LeadSection) => {
               return data
            })
         )
         .subscribe(data => {
            this.leadSection = { ...(data['section'] || this._initialLeadSection as LeadSection) }

         })
   }

   ngAfterViewInit() {
      this.sidebarToggle = document.getElementById('sidebar-toggle')
      if (this.sidebarToggle) {
         //Uncomment Below to persist sidebar toggle between refreshes
         //if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
         //   document.body.classList.toggle('sb-sidenav-toggled')
         //}
         this.sidebarToggle.addEventListener('click', event => {
            event.preventDefault()
            document.body.classList.toggle('sb-sidenav-toggled')
            //localStorage.setItem(
            //   'sb|sidebar-toggle',
            //   document.body.classList.contains('sb-sidenav-toggled').toString()
            //)
         })
      }

      const sidebarWrapperLinks = document.body.querySelectorAll(
         '#sidebar-wrapper li > a'
      )
      if (sidebarWrapperLinks) {
         sidebarWrapperLinks.forEach(link => {
            link.addEventListener('click', event => {
               //only toggle sidebar if toggle button is visible (has offsetParent)
               if (this.sidebarToggle.offsetParent) {
                  document.body.classList.toggle('sb-sidenav-toggled')
                  //localStorage.setItem(
                  //   'sb|sidebar-toggle',
                  //   document.body.classList
                  //      .contains('sb-sidenav-toggled')
                  //      .toString()
                  //)
               }

               // issue with tooltips that won't go away when a link is clicked - remove it
               let tooltips = document.querySelectorAll("div[role='tooltip']")
               tooltips.forEach(item => item.remove())
            })
         })
      }
   }

   sayHi(args?: any) {
      const date = this._commonService.dateTime.formatDate(null, null, true)
      this._commonService.messageUser(`${date}: ${(args?.title || 'whatevs')}`)
   }

   getMeUserInfo() {
      this.user$.pipe(take(1)).subscribe(user => {
         //client subscribes: window.subscribeToAngularEvent('angular.learning.emit-user-info', (user) =>  console.log('Do something with user:', user))
         //client calls this: window.fireAngularEvent('angular.learning.retrieve-user-info')
         this._globalPubSubService.fireEvent('angular.learning.emit-user-info', [user])
      })
   }

   triggerAndEmit(blah?: any) {
      this._authService.initializeSiteConfig().pipe(take(1)).subscribe(confg => {
         this._globalPubSubService.fireEvent('angular.learning.emit-something', [confg || 'NOPE - nothing!'])
      })
      //client subscribes: window.subscribeToAngularEvent('angular.learning.emit-something', (msg) =>  console.log('i am here', msg))
      //client calls this: window.fireAngularEvent('angular.learning.trigger-and-emit-something')
   }
}
