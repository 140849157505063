import { createFeatureSelector, createReducer, on } from '@ngrx/store'
import { ISiteConfiguration } from '@appShared/interfaces/[Interface-based]/site-configuration.interface'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { IUser } from '@appShared/interfaces/[Interface-based]/user.interface'
import { AppActions } from '../actions'

export interface AppState {
   config: ISiteConfiguration,
   user: IUser,
   hasVerifiedSSN: boolean
   error: string
}

const intialAppState: AppState = {
   config: null,
   user: null,
   hasVerifiedSSN: false,
   error: ''
}

export const getAppFeatureState = createFeatureSelector<AppState>('app')

export const appReducer = createReducer<AppState>(
   intialAppState,
   on(AppActions.loadSiteConfigSuccess, (state, action): AppState => {
      return {
         ...state,
         config: action.siteConfig,
         error: ''
      }
   }),
   on(AppActions.loadSiteConfigFailure, (state, action): AppState => {
      return {
         ...state,
         config: null,
         error: action.error
      }
   }),
   on(AppActions.setUser, (state, action): AppState => {
      const user = action.user
      //only set user if there is a profile on the model
      return {
         ...state,
         user: user?.profile ? user : null
      }

   }),
   on(AppActions.setUserMustResetPassword, (state, action): AppState => {
      const mustResetPassword = action.mustResetPassword
      const user = {
         ...state.user,
         mustResetPassword
      } as IUser

      return {
         ...state,
         user
      }

   }),
   on(AppActions.setUserProfile, (state, action): AppState => {
      const user = state.user
      const profile = action.profile

      return {
         ...state,
         user: {
            ...user,
            profile
         }
      }

   }),
   on(AppActions.setUserAccount, (state, action): AppState => {
      const user = state.user
      const profile = user?.profile || {} as IProfile
      const account = action.account

      return {
         ...state,
         user: {
            ...user,
            profile: {
               ...profile,
               account
            }
         }
      }

   })
)
