import { Injectable } from '@angular/core'
import { TitleCasePipe } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, of, take, tap, throwError } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import {
   IAccount,
   IContact,
   IPaymentInstrument,
   IPaymentInstrumentSubmission,
   IProfile,
   IProfileSubmission,
   ISubscription,
   ISubscriptionPaymentTransaction,
   ISubscriptionAuthorization,
   IProgressBadge,
   ISubscriptionUpdateSubmission,
} from '@appShared/interfaces/[Model-based]'
import { IUser } from '@appShared/interfaces/[Interface-based]/user.interface'
import { PaymentInstrumentType_ } from '@appShared/services/lookup/[CodeGen]/payment-instrument-type.domain'
import { PaymentCardType_ } from '@appShared/services/lookup/[CodeGen]/payment-card-type.domain'

export interface IClientPaymentInstrument extends IPaymentInstrument {
   paymentCardImageUrl: string
}

@Injectable({
   providedIn: 'root'
})
export class AccountsService {
   private _accountsApi = '/api/core/accounts'

   // https://stackoverflow.com/a/71961946/1146117
   private _httpOptions = {}

   private _subscriptions: BehaviorSubject<
      ISubscription[]
   > = new BehaviorSubject<ISubscription[]>(null)
   public get subscriptions$(): Observable<ISubscription[]> {
      return this._subscriptions.asObservable()
   }

   private _subscriptionAuthorizations: BehaviorSubject<
      ISubscriptionAuthorization[]
   > = new BehaviorSubject<ISubscriptionAuthorization[]>(null)
   public get activeSubscriptionAuthorizations$(): Observable<ISubscriptionAuthorization[]> {
      return this._subscriptionAuthorizations.asObservable()
   }

   private _profiles: BehaviorSubject<
      IProfile[]
   > = new BehaviorSubject<IProfile[]>(null)
   public get profiles$(): Observable<IProfile[]> {
      return this._profiles.asObservable()
   }

   constructor(
      private _appFacade: AppFacade,
      private _http: HttpClient,
      private _commonService: CommonService,
      private _titleCasePipe: TitleCasePipe
   ) {
      this._httpOptions = _commonService.httpOptions()
   }

   /*
   * private methods
   * */

   private _setUserState(user?: IUser) {
      console.log('setting updated user in store', user)
      if (!user) {
         this._appFacade.setUser(null)
         return
      }

      this._appFacade.setUser(user)
   }

   /*
   * public methods
   * */

   refreshCurrentUser(): Observable<IUser> {
      const url = `${this._accountsApi}/user`

      return this._http.get<IUser>(url).pipe(
         take(1),
         tap(user => this._setUserState(user))
      )
   }

   createUserWithToken(
      token: string,
      profileSubmission: IProfileSubmission
   ): Observable<number> {
      if (!token || !profileSubmission) {
         throwError(() => new Error('No all token/profileSubmission passed!'))
         //return new Promise((resolve, reject) =>
         //   reject('No all token/profileSubmission passed!')
         //)
      }

      const url = `${this._accountsApi
         }/user?token=${token}`

      return this._http.post<number>(
         url,
         profileSubmission,
         this._httpOptions
      )

      //const request$ = this._http.post<number>(
      //   url,
      //   profileSubmission,
      //   this._httpOptions
      //).pipe(take(1))

      //return await lastValueFrom<number>(request$)
   }

   subscriptionPaymentTransactions$(): Observable<ISubscriptionPaymentTransaction[]> {
      const url = `${this._accountsApi}/subscription-payment-transactions`

      return this._http.get<ISubscriptionPaymentTransaction[]>(url)
         .pipe(
            map(subPayTrans => {
               subPayTrans.forEach(subPayTran => {
                  subPayTran = this.getFormattedUpdatedPaymentInstrument(subPayTran.paymentTransaction)
               })

               return subPayTrans
            })
         )
   }

   getFormattedUpdatedPaymentInstrument(paymentInstrumentParent: any): any {
      let paymentInstrument = this.returnClientPaymentInstrument(paymentInstrumentParent.paymentInstrument)
      let paymentCard = paymentInstrument.paymentCard
      paymentInstrumentParent.paymentInstrument = {
         ...paymentInstrumentParent.paymentInstrument,
         paymentCard,
         paymentCardImageUrl: paymentInstrument.paymentCardImageUrl,
         sourceName: paymentInstrument.sourceName
      } as any /* any to account for expando property paymentCardImageUrl */

      return paymentInstrumentParent
   }

   getNewProfile(): IProfile {
      return {
         id: 0,
         contact: {
            info: {}
         } as IContact,
         info: {}
      } as IProfile
   }

   getSubscriptions() {
      const url = `${this._accountsApi}/subscriptions`

      this._http.get<ISubscription[]>(url).pipe(
         take(1)
      ).subscribe(subscriptions => {
         this._subscriptions.next(subscriptions)
      })
   }

   getActiveSubscriptionAuthorizations() {
      const url = `${this._accountsApi}/subscription-authorizations`

      this._http.get<ISubscriptionAuthorization[]>(url).pipe(
         take(1)
      ).subscribe(subscriptionAuthorizations => {
         subscriptionAuthorizations.forEach(subscription => {
            subscription = this.getFormattedUpdatedPaymentInstrument(subscription)
         })
         this._subscriptionAuthorizations.next([...subscriptionAuthorizations])
      })
   }

   getProfiles(includeAccountOwner?: boolean) {
      const url = `${this._accountsApi}/profiles`

      //TODO - testing
      //let subscriptionSeats = [
      //   {
      //      id: 1234,
      //      profileId: 1234,
      //      profile: {
      //         contact: {
      //            id: 1234,
      //            firstName: 'Bubba',
      //            lastName: 'Johnson',
      //            emailAddress: 'bubba@johnson.com'
      //         } as IContact
      //      } as IProfile
      //   } as ISubscriptionSeat,
      //   {
      //      id: 2345,
      //      profileId: 2345,
      //      profile: {
      //         contact: {
      //            id: 2345,
      //            firstName: 'Jackie',
      //            lastName: 'Chan',
      //            emailAddress: 'jackie@chan.com'
      //         } as IContact
      //      } as IProfile
      //   } as ISubscriptionSeat,
      //   {
      //      id: 3456,
      //      profileId: 3456,
      //      profile: {
      //         contact: {
      //            id: 3456,
      //            firstName: 'Jojo',
      //            lastName: 'Smithersonstein',
      //            emailAddress: 'jojo@smithersonstein.com'
      //         } as IContact
      //      } as IProfile
      //   } as ISubscriptionSeat,
      //] as ISubscriptionSeat[]
      //this._subscriptionSeats.next(subscriptionSeats)


      /**
       * TODO - review for removal of nested subscription
       *    https://medium.com/@kateb.fedy/angular-best-practices-to-adapt-4c7687b16c85
       *    11. Avoid having Subscriptions Inside Subscriptions
       * */
      this._http.get<IProfile[]>(url).pipe(
         take(1)
      ).subscribe(profiles => {
         if (!includeAccountOwner) {
            //get currentlogged in User to test profileId
            this._appFacade.user$.pipe(take(1)).subscribe(user => {
               const currentProfileId = (user.profile as IProfile).id
               profiles = [...profiles].filter(profile => profile.id !== currentProfileId)
               this._profiles.next(profiles)
            })

         } else {
            this._profiles.next(profiles)
         }
      })
   }

   getProfileBadges(profileId: number): Observable<IProgressBadge[]> {

      if (profileId) {
         const url = `${this._accountsApi}/profiles/${profileId}/badges`

         return this._http.get<IProgressBadge[]>(url)
      }

      return of([])
   }

   returnClientPaymentInstrument(paymentInstrument: IPaymentInstrument) {
      let clientPaymentInstrument = {
         ...paymentInstrument
      } as IClientPaymentInstrument

      if (
         clientPaymentInstrument.typeCode === PaymentInstrumentType_.PaymentCard
      ) {
         const ccExpiration = clientPaymentInstrument.paymentCard.expiration

         if (ccExpiration?.length > 5) {
            clientPaymentInstrument.paymentCard.expiration = ccExpiration
               ? this._commonService.dateTime.formatDate(
                  new Date(ccExpiration),
                  'MM/YY'
               )
               : ''
         }

         if (
            [
               PaymentCardType_.Amex,
               PaymentCardType_.Discover,
               PaymentCardType_.MasterCard,
               PaymentCardType_.VISA
            ].includes(clientPaymentInstrument?.info.card?.typeCode || 0)
         ) {
            clientPaymentInstrument.paymentCardImageUrl = clientPaymentInstrument.sourceName
               ? `/assets/images/cc/${clientPaymentInstrument.sourceName.toLowerCase()}.png`
               : ''
         }

      } else if (
         clientPaymentInstrument.typeCode === PaymentInstrumentType_.EftRecord
      ) {
         /* Title case EFT Bank Names */
         let sourceName = this._titleCasePipe.transform(
            clientPaymentInstrument.sourceName
         )
         clientPaymentInstrument.sourceName = sourceName

         //don't show expiration in case of EFT
         //clientPaymentInstrument.expiration = ''
      }

      return clientPaymentInstrument
   }

   async createUpdateProfile(profileSubmission: IProfileSubmission, profileId?: number): Promise<any> {

      if (!profileSubmission) {
         return Promise.reject('No profileSubmission passed!')
      }

      let url = `${this._accountsApi}/profiles`
      const options = this._commonService.httpOptions()
      let request$: Observable<IProfile>

      if (profileId) {
         /* update */
         url = `${url}/${profileId}`
         request$ = this._http.put<IProfile>(url, profileSubmission, options).pipe(take(1))
      } else {
         /* else create */
         request$ = this._http.post<IProfile>(url, profileSubmission, options).pipe(take(1))
      }

      return await lastValueFrom<IProfile>(request$)
   }

   async deleteProfile(
      profileId: number
   ): Promise<any> {
      if (!profileId) {
         return Promise.reject('profileId NOT passed!')
      }

      const url = `${this._accountsApi}/profiles/${profileId}`

      const request$ = this._http.delete(url, this._httpOptions).pipe(take(1))

      return await lastValueFrom(request$)
   }

   //async updateSubscriptionAuthorizations(
   //   paymentInstrumentSubmission: IPaymentInstrumentSubmission
   //): Promise<IAccount> {
   //   if (!paymentInstrumentSubmission) {
   //      return Promise.reject('Not all paymentInstrumentSubmission passed!')
   //   }

   //   const url = `${this._accountsApi}/subscription-authorizations`

   //   const request$ = this._http.put<IAccount>(url, paymentInstrumentSubmission, this._httpOptions).pipe(take(1))

   //   return await lastValueFrom<IAccount>(request$)
   //}

   updateSubscriptionAuthorizations(
      paymentInstrumentSubmission: IPaymentInstrumentSubmission
   ): Observable<IAccount> {
      if (!paymentInstrumentSubmission) {
         throwError(() => new Error('Not all paymentInstrumentSubmission passed!'))
      }

      const url = `${this._accountsApi}/subscription-authorizations`

      return this._http.put<IAccount>(url, paymentInstrumentSubmission, this._httpOptions)
   }

   async updateSubscription(
      subscriptionUpdateSubmission: ISubscriptionUpdateSubmission
   ): Promise<ISubscription> {
      if (!subscriptionUpdateSubmission?.subscriptionId) {
         return Promise.reject('Not all subscriptionUpdateSubmission passed!')
      }

      const url = `${this._accountsApi}/subscription/${subscriptionUpdateSubmission.subscriptionId}`

      const request$ = this._http.put<ISubscription>(url, subscriptionUpdateSubmission, this._httpOptions).pipe(take(1))

      return await lastValueFrom<ISubscription>(request$)
   }
}
