import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { lastValueFrom, Observable, throwError } from 'rxjs'
import { filter, take, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { State } from '@appState/index'
import { AppFacade } from '@appShared/services/app.facade'
import { CommonService } from '@appShared/services/common.service'
import { ISiteConfiguration } from '@appShared/interfaces/[Interface-based]/site-configuration.interface'
import { AppActions } from '@appState/actions'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class AuthService {
   private _authApi = '/auth';
   private _siteConfig: ISiteConfiguration

   // https://stackoverflow.com/a/71961946/1146117
   private _httpOptions = {}

   constructor(
      private _httpClient: HttpClient,
      private _store: Store<State>,
      commonService: CommonService,
      _appFacade: AppFacade,
   ) {
      this._httpOptions = commonService.httpOptions()

      _appFacade.siteConfig$
         .pipe(
            tap(siteConfig => _appFacade.prefetchSiteConfig(siteConfig)),
            filter(data => !!data), // filter till has data
            take(1) // now that is has filtered data - take 1
         )
         .subscribe(config => (this._siteConfig = config))
   }

   initializeSiteConfig(): Observable<ISiteConfiguration> {
      return this._httpClient.get<ISiteConfiguration>('api/config'/*, this._httpOptions*/).pipe(
         take(1),
         tap(siteConfig => {

            // override our environment
            if (siteConfig.siteUri) {
               environment.baseUri = siteConfig.siteUri
            }

            this._store.dispatch(AppActions.loadSiteConfigSuccess({ siteConfig }))
         })
      )
   }

   delegateLogin(userPrincipalId: number): Observable<any> {
      if (!userPrincipalId) {
         throwError(() => new Error('No userPrincipalId  passed!'))
      }

      let headers = new HttpHeaders()
         .set(environment.userPrincipalIdHeaderName, userPrincipalId.toString())
         .set(environment.oAuthClientIdHeaderName, environment.oAuthClientIdentifier)
         .set(environment.oAuthClientSecretHeaderName, environment.oAuthClientSecret)

      return this._httpClient.post<any>(this._siteConfig.delegatedAuthorizationUri, null, {
         headers,
         withCredentials: true
      })

      //const request$ = this._httpClient.post(this._siteConfig.delegatedAuthorizationUri, null, {
      //   headers,
      //   withCredentials: true
      //})

      //return await lastValueFrom(request$)
   }

   async resetPassword(password: string): Promise<any> {
      if (!password) {
         return new Promise((resolve, reject) => reject('No password passed!'))
      }

      const url = `${this._authApi}/set-password`

      const request$ = this._httpClient.put(url, { password }, this._httpOptions).pipe(take(1))

      return await lastValueFrom(request$)
   }
}
