import {
   AfterContentChecked,
   Component,
   Input,
   OnChanges,
   OnInit,
   SimpleChanges
} from '@angular/core'
import { NgIf, AsyncPipe } from '@angular/common'
import { RouterLink } from '@angular/router'
import { Observable, tap } from 'rxjs'
import { UrlService } from '@appShared/services/url.service'
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import { IUser } from '@appShared/interfaces/[Interface-based]/user.interface'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { ContentRegistrationProgressesService } from '@appShared/services/content-registration-progresses.service'
import {
   LeadSection,
   LeadSections,
   LeadSection_
} from '@appShared/services/lookup/lead-section'
import { IContactPartitionProgress } from '@appShared/interfaces/[Model-based]/contact-partition-progress.interface'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-internal-header',
    templateUrl: './internal-header.component.html',
    styleUrls: ['./internal-header.component.less'],
    standalone: true,
    imports: [RouterLink, NgIf, AsyncPipe]
})
export class InternalHeaderComponent implements OnInit, OnChanges, AfterContentChecked {
   @Input() leadSection: LeadSection
   contactPartitionProgress$: Observable<IContactPartitionProgress>
   headerTitle$: Observable<string>
   user$: Observable<IUser>
   isDotNetInteroperable$: Observable<boolean>
   routes = environment.routes;

   private _initialLeadSection = LeadSections.find(
      section => section.code === LeadSection_.Home
   );

   constructor(
      private _appFacade: AppFacade,
      private _commonService: CommonService,
      private _urlService: UrlService,
      contentRegistrationProgressesService: ContentRegistrationProgressesService,
      hybridService: HybridWebviewInteropService
   ) {
      this.isDotNetInteroperable$ = hybridService.isDotNetInteroperable$

      this.contactPartitionProgress$ = contentRegistrationProgressesService.contactPartitionProgress$
         .pipe(
            tap(contactPartitionProgress => {
               console.log(`contactPartitionProgress$:`, contactPartitionProgress)
            })
         )
   }

   ngOnInit() {
      this.user$ = this._appFacade.user$
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes && !changes['leadSection']) {
         this.leadSection == this._initialLeadSection
      }
   }

   ngAfterContentChecked(): void {
      setTimeout(() => { this.headerTitle$ = this._urlService.headerTitle$ })
   }

   /*
   * public methods
   * */

   comingSoon(areaName?: string) {
      const message = areaName ? `"${areaName}" feature is coming soon.` : ''
      this._commonService.comingSoon(message)
   }

   logout() {
      this._commonService.logout()
   }
}
