import { Injectable, NgZone } from "@angular/core"

/**
 * https://tonyding.gitbook.io/angular/angular-tips-1/communicate-with-angular-components-outside-of-angular
 * Service that allows Angular components to receive and fire
 * events from outside
 *
 * Usage from outside of Angular:
 *   window.fireAngularEvent('sampleEventName', args)
 *   window.subscribeToAngularEvent('sampleEventName', fn)
 *
 * Usage from Angular component:
 *   globalPubSub.fireEvent('sampleEventName', args)
 *   globalPubSub.subscribe('sampleEventName', fn, context)
 */
@Injectable({ providedIn: 'root' })
export class GlobalPubSubService {

   allowedEvents = [
      "angular.learning.say-hi",
      "angular.learning.retrieve-user-info",
      "angular.learning.emit-user-info",
      "angular.learning.trigger-and-emit-something",
      "angular.learning.emit-something"
   ];

   private subscriptions: { [key: string]: Function[] } = {};
   private subscriptionContext: { [key: string]: any } = {};

   constructor(zone: NgZone, window: Window ) {
      this.allowedEvents.forEach((eventName) => {
         this.subscriptions[eventName] = []
      })

      window['fireAngularEvent'] = (eventName, args) => {
         if (!this.subscriptions[eventName]) {
            throw new Error('Event has to be defined in the event list.')
         }

         zone.run(() => {
            this.fireEvent(eventName, args)
         })
      }

      window['subscribeToAngularEvent'] = (eventName, fn) => {
         this.subscribe(eventName, fn)
      }
   }

   subscribe(eventName: string, fn: Function, context?: any) {
      if (!this.subscriptions[eventName]) {
         throw new Error('Event has to be defined in the event list.')
      }
      if (context) {
         this.subscriptionContext[eventName] = context
      }

      this.subscriptions[eventName].push(fn)
   }

   fireEvent(eventName: string, args) {
      if (!this.subscriptions[eventName]) {
         throw new Error('Event has to be defined in the event list.')
      }
      let context = this.subscriptionContext[eventName]
      this.subscriptions[eventName].forEach((fn) => {
         if (context) {
            fn.apply(context, args)
         } else {
            fn.apply(null, args)
         }
      })
   }
}
