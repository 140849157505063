import { Injectable } from '@angular/core'
import {
   ActivatedRouteSnapshot,
   RouterStateSnapshot,
   Router
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, filter, switchMap, take } from 'rxjs/operators'
import { CommonService } from './common.service'
import { NotificationsService, SiteNotification, SiteNotificationType_ } from '@appShared/services/notifications.service'
import { AppFacade } from '@appShared/services/app.facade'
import { ISubscription } from '@appShared/interfaces/[Model-based]/subscription.interface'
import { SubscriptionStatus_ } from '@appShared/services/lookup/[CodeGen]/subscription-status.domain'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class AuthGuardUserService {
   constructor(
      private _commonService: CommonService,
      private _notificationsService: NotificationsService,
      private _router: Router,
      private _appFacade: AppFacade
   ) { }

   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean> {
      // https://levelup.gitconnected.com/ngrx-and-angular-route-guards-a3fc3d5255f8
      return this._appFacade.user$.pipe(
         take(1),
         switchMap(user => {
            const returnUrl = state.url
            const memberRoutes = environment.routes.member
            const contentRoutes = environment.routes.content
            const siteNotificationsUrl = `/${memberRoutes.siteNotifications.uri}`
            const setPasswordUrl = `/${memberRoutes.setPassword.uri}`
            const profile = user?.profile
            const subscriptions = profile?.account?.subscriptions || [] as ISubscription[]
            const anyActiveSubscription = subscriptions.find(sub => [SubscriptionStatus_.Active].includes(sub.statusCode))

            /* TODO remove */
            //if (returnUrl !== siteNotificationsUrl) {
            //   const siteNotification: SiteNotification = {
            //      typeCode: SiteNotificationType_.Information,
            //      notification: `<h3>Account Issue</h3>
            //                     <p>Please contact <a href="${environment.contactUsUri}" target="_blank">customer service.</p>
            //                     <p>Thank You</p>`
            //   }
            //   this._notificationsService.setCurrentNotification(siteNotification)
            //   this._router.navigate([memberRoutes.siteNotifications.uri])
            //   return of(false)
            //}

            if (!user) {
               this._commonService.navigateToLogin(returnUrl)

               return of(false)
            }

            if (user.mustResetPassword
               && !returnUrl.startsWith(setPasswordUrl)) {
               // force them to set-password
               this._router.navigate([setPasswordUrl])

               return of(false)
            }

            if (!user.mustResetPassword
               && returnUrl.startsWith(setPasswordUrl)) {
               // don't need set-password
               this._router.navigate([contentRoutes.uri])

               return of(false)

            }

            if (user.isAdmin || user.isRep) {
               const siteNotification: SiteNotification = {
                  typeCode: SiteNotificationType_.Information,
                  notification: `<h3>Not Licensed</h3>
                                    <p>Internal accounts are not licensed to access content</p>
                                    <p>Thank You</p>`
               }
               this._notificationsService.setCurrentNotification(siteNotification)
               setTimeout(() => this._router.navigate([memberRoutes.siteNotifications.uri]), 0)

               return of(false)
            }

            if (!(user.isUser || user.isCustomer || user.isPromo)) {
               const siteNotification: SiteNotification = {
                  typeCode: SiteNotificationType_.Information,
                  notification: `<h3>Not Authorized</h3>
                                    <p>Please contact <a href="${environment.contactUsUri}" target="_blank">customer service</a>
                                       if you believe you should have access to this site.</p>
                                    <p>Thank You</p>`
               }
               this._notificationsService.setCurrentNotification(siteNotification)
               setTimeout(() => this._router.navigate([memberRoutes.siteNotifications.uri]), 0)

               return of(false)
            }

            if (!anyActiveSubscription && returnUrl !== siteNotificationsUrl) {

               const siteNotification: SiteNotification = {
                  typeCode: SiteNotificationType_.Information,
                  notification: `<h3>Account Issue</h3>
                                    <p>No active subscription on record. Please contact
                                       <a href="${environment.contactUsUri}" target="_blank">customer service</a>.
                                    </p>
                                    <p>Thank You</p>`
               }
               this._notificationsService.setCurrentNotification(siteNotification)
               setTimeout(() => this._router.navigate([memberRoutes.siteNotifications.uri]), 0)

               return of(false)
            }

            return of(true)
         }),
         catchError(() => of(false))
      )
   }
}
