
<div class="bg-page-container d-flex flex-column justify-content-between h-100">

   <section class="bg-site-tertiary p-3">
      <div class="container d-flex justify-content-end gap-3">
         <button class="btn btn-site-primary fw-bold"
                 (click)="navigateToLogin()">
            Login
         </button>
      </div>
   </section>

   <div class="product-sections d-flex justify-content-center align-items-center gap-3">

      <section class="d-flex flex-column align-items-center p-4">
         <h1>
            COLLEGE ENTRANCE ADVANTAGE
         </h1>
         <h5 class="mb-4"><em>The Program that helps you conquer the SAT and ACT.</em></h5>
         <img class="mx-auto img-fluid img_border" src="{{moduleCourseImagesFolderUri}}/CED.png">
      </section>

      <section class="d-flex flex-column align-items-center p-4">
         <h1>
            ADVANCED PLACEMENT PREP
         </h1>
         <h5 class="mb-4"><em>Everything you need for top AP scores.</em></h5>
         <img class="mx-auto img-fluid img_border" src="{{moduleCourseImagesFolderUri}}/APD.png">
      </section>

   </div>


   <section class="bg-site-secondary">

      <div class="container">

         <div class="row pt-5">
            <div class="col-sm-12">
               <h2>LET'S FACE IT...</h2>
            </div>
         </div>
         <div class="row text-justify p-4">
            <div class="col-sm-6 p-2">
               <p class="pl-3 pr-3">
                  Preparing for these exams is stressful. Study the wrong material,
                  or not enough, and placement scores suffer. On the other hand, what
                  if you had a "friend" who knew exactly what to study...
               </p>
               <p class="pl-3 pr-3">
                  And what if this "friend" provided your child with the exact study
                  materials he needed - right on your home computer or laptop?
                  Introducing the Southwestern Advantage College Entrance Advantage:
                  Your new, powerful ally to help your children get into the university
                  that they deserve. Our program harnesses the power of The Princeton
                  Review, a program which has helped millions of students get into the
                  colleges that they deserve. Entrance exams require a unique study approach.
                  College Entrance Advantage gives your children exactly what they need.
               </p>

               <div class="d-sm-none d-md-none d-lg-none text-center">
                  <img src="/assets/images/lamp.png" width="150">
                  <h5 class="lamp-text">
                     Don't just pass college placement test. <u>Conquer them!</u>
                  </h5>
               </div>
               <div class="d-none d-sm-block">
                  <img src="/assets/images/lampwords.png" width="350">
               </div>

            </div>

            <div class="col-sm-6 p-2">
               <h4>FEATURES INCLUDE</h4>
               <ul class="p-2">
                  <li class="pb-2">
                     Our program harnesses the power of The Princeton Review -
                     a proven study course which helps 4 out of 5 students get
                     into their top choice schools.<br>
                  </li>
                  <li class="pb-2">
                     Princeton Review students scored, on average,
                     190 points higher than non-Princeton
                     Review students on the SAT.<br>
                  </li>
                  <li class="pb-2">
                     Get help with every subject on the entrance exam as well as
                     help with the real challenge of these tests: speed and strategy.<br>
                  </li>
                  <li class="pb-2">
                     Each package includes; 3 full-length practice tests including
                     essays, a 1,000 page manual, and 3 CD's jam-packed with everything
                     your high schooler needs to get a higher score.<br>
                  </li>
                  <li class="pb-2">
                     The College Entrance Advantage provides over 50 hours of prep
                     material to help you control your college destiny.<br>
                  </li>
               </ul>
            </div>
         </div>

      </div>

   </section>

   <!--<section class="bg-site-tertiary">
      <div class="container">
         <div class="row">
            <div class="col-sm-12">
               <div class="py-3">
                  <h3 class="text-white text-center">Courses</h3>
                  <div class="row">
                     <div class="col-xs-12 col-sm-6 col-mg-4 col-lg-3 text-center">
                        <h5 class="text-white pt-2 pb-2">
                           <br>SAT Preparation
                        </h5>
                        <img class="w-100 img-fluid card-img-top card-img-bottom" src="/assets/images/courses/courses_sat.png">
                     </div>
                     <div class="col-xs-12 col-sm-6 col-mg-4 col-lg-3 text-center">
                        <h5 class="text-white pt-2 pb-2">
                           <br>ACT Preparation
                        </h5>
                        <img class="w-100 img-fluid card-img-top card-img-bottom" src="/assets/images/courses/courses_act.png">
                     </div>
                     <div class="col-xs-12 col-sm-6 col-mg-4 col-lg-3 text-center">
                        <h5 class="text-white pt-2 pb-2">
                           Understanding <br>College Admission
                        </h5>
                        <img class="w-100 img-fluid card-img-top card-img-bottom" src="/assets/images/courses/courses_aid.png">
                     </div>
                     <div class="col-xs-12 col-sm-6 col-mg-4 col-lg-3 text-center">
                        <h5 class="text-white pt-2 pb-2">
                           Understanding <br>Financial Aid
                        </h5>
                        <img class="w-100 img-fluid card-img-top card-img-bottom" src="/assets/images/courses/courses_admission.png">
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   </section>-->

   <!--<div class="d-flex flex-column p-2 p-lg-5 pt-md-4 mb-3 container position-relative">-->
   <!--<img src="/assets/images/logos/advantage-logo.png" class="logo d-none d-sm-block" alt="">

   <div class="fw-bold text-site-secondary fs-4 fst-italic mt-3">
      It takes more than classroom knowledge alone to achieve our goals in life.
   </div>

   <div class="font-site-secondary text-site-secondary fs-5 fst-italic mt-3">
      LEAD offers positive change through personal Leadership, Education, Accountability, and Difference-making.
   </div>

   <div class="font-site-secondary text-site-secondary fs-5 fst-italic  mt-3">
      Enjoy courses on life skills, strengthen your academics with virtual tutoring, gain leadership knowledge,
      and be part of a community that believes in personal responsibility and serving others.
   </div>-->
   <!--<div class="mt-5 mx-auto d-flex flex-column">
      <a class="btn btn-lg btn-site-secondary fw-bold fs-3 text-uppercase"
         routerLink="/{{registrationUri}}">Become a member</a>

      <a class="btn btn-lg bg-light fw-bold text-site-secondary fs-3 text-uppercase mt-3 bordered"
         [routerLink]="['/guest-access']">Guest Access</a>
   </div>-->
   <!--</div>-->

</div>

