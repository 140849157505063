import {
   Params
} from '@angular/router'
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store'

import * as fromRouter from '@ngrx/router-store'
import * as fromApp from './app.reducer'
//import * as fromShareholder from './shareholder.reducer'
//import * as fromAdmin from './admin.reducer'

export interface RouterStateUrl {
   /* can extend more if needed */
   url: string
   queryParams: Params
   params: Params
}

export interface State {
   //admin: fromAdmin.AdminState
   app: fromApp.AppState
   routerReducer: fromRouter.RouterReducerState<RouterStateUrl>
   //shareholder: fromShareholder.ShareholderState
}

export const reducers: ActionReducerMap<State> = {
   //admin: fromAdmin.adminReducer,
   app: fromApp.appReducer,
   routerReducer: fromRouter.routerReducer,
   //shareholder: fromShareholder.employeeReducer
}

export const getRouterState = createFeatureSelector<
   fromRouter.RouterReducerState<RouterStateUrl>
>('router-reducer')
