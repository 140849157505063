import { createAction, props } from '@ngrx/store'
import {
   ISiteConfiguration,
   IUser
} from '@appShared/interfaces/[Interface-based]'
import { IAccount } from '@appShared/interfaces/[Model-based]/account.interface'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { IProfileSubmission } from '@appShared/interfaces/[Model-based]/profile-submission.interface'

const eventPrefix = '[App API]'

export const loadSiteConfig = createAction(`${eventPrefix} Set Site Config`)

export const loadSiteConfigSuccess = createAction(
   `${eventPrefix} Load Site Config Success`,
   props<{ siteConfig: ISiteConfiguration }>()
)

export const loadSiteConfigFailure = createAction(
   `${eventPrefix} Load Site Config Failure`,
   props<{ error: string }>()
)

export const setUser = createAction(
   `${eventPrefix} Set Current User`,
   props<{ user: IUser }>()
)

export const setUserAccount = createAction(
   `${eventPrefix} Set Current User Account`,
   props<{ account: IAccount }>()
)

export const setUserProfile = createAction(
   `${eventPrefix} Set Current User Profile`,
   props<{ profile: IProfile}>()
)

export const setUserMustResetPassword = createAction(
   `${eventPrefix} Set Current User Must Reset Password`,
   props<{ mustResetPassword: boolean }>()
)

//export const setHasVerifiedSSN = createAction(
//  `${eventPrefix} Set Has Verified SSN`
//)

//export const toggleIsAdminOnlyMode = createAction(
//  `${eventPrefix} Toggle Site Is Offline`,
//  props<{ isAdminOnlyMode: boolean }>()
//)

//export const toggleIsAdminOnlyModeSuccess = createAction(
//  `${eventPrefix} Toggle Site Is Offline Success`,
//  props<{ isAdminOnlyMode: boolean }>()
//)

//export const toggleIsAdminOnlyModeFailure = createAction(
//  `${eventPrefix} Toggle Site Is Offline Failure`,
//  props<{ error: string }>()
//)
