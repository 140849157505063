import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonService } from '@appShared/services/common.service';
import { environment } from '@appEnvironments/environment';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class LandingComponent {

   moduleCourseImagesFolderUri = `${environment.publicCdn.uri}/${environment.publicCdn.moduleCourseImagesFolder}`

   constructor(private _commonSerivce: CommonService) { }

   /*
   * private methods
   * */

   /*
   * public methods
   * */

   navigateToLogin() {
      this._commonSerivce.navigateToLogin()
   }

}
