import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { NgIf } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { take } from 'rxjs'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { CommonService } from '@appShared/services/common.service'
import { UrlService } from '@appShared/services/url.service'
import { ContentRegistrationProgressesService } from '@appShared/services/content-registration-progresses.service'
import { ContentRegistrationsService } from '@appShared/services/content-registrations.service'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { AppFacade } from '@appShared/services/app.facade'
import { IUser } from '@appShared/interfaces/[Interface-based]/user.interface'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-iframe-player',
    templateUrl: './iframe-player.component.html',
    styles: [`
      .exit-button {
         font-size:11px;
         opacity:.75;
         padding-top:3px;
         padding-bottom:1px;
      }
   `],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf]
})
export class IFramePlayerComponent implements OnInit {
   @ViewChild('iframe') iframe: ElementRef
   @Input() frameSourceInput: string
   private _contentRootUri = environment.routes.content.uri
   private _coursesUri = environment.routes.content.uri
   private _user: IUser
   private _modalRef: NgbModalRef
   token: string
   iframeSrc: string
   @ViewChild('modaltemplate') modalTemplate: TemplateRef<Element>

   constructor(
      private _commonService: CommonService,
      private _urlService: UrlService,
      private _router: Router,
      private _activatedRoute: ActivatedRoute,
      private _contentRegistrationsService: ContentRegistrationsService,
      private _contentRegistrationProgressesService: ContentRegistrationProgressesService,
      private _hybridWebviewInteropService: HybridWebviewInteropService,
      private _modalService: NgbModal,
      appFacade: AppFacade,
      titleService: Title
   ) {
      titleService.setTitle(environment.routes.title)

      appFacade.user$.pipe(
         take(1)
      ).subscribe(user => {
         this._user = user
      });
   }

   //https%3A%2F%2Fsite-68952654.bcvp0rtal.com
   ngOnInit() {
      this.token = this._activatedRoute.snapshot.queryParamMap.get('token')

      if (this.token) {

         //this._contentRegistrationsService.getCourseLink(this.token)
         //   .then(registration => {
         //      this._setCourseUri(registration.launchLink)
         //   })
         //   .catch(() => {
         //      this._router.navigate([this._coursesUri])
         //   })
      } else {
         const src = this.frameSourceInput || this._activatedRoute.snapshot.queryParamMap.get('src')

         if (src) {
            this._setCourseUri(decodeURIComponent(src))
         } else {
            this._router.navigate([this._contentRootUri])
         }
      }

      setTimeout(() => { this._urlService.setHeaderTitle('') })
   }

   /*
   * private methods
   * */

   private _setCourseUri(courseUri: string) {
      setTimeout(() => {
         if (this.iframe && courseUri) {
            this.iframe.nativeElement.src = courseUri
         }
      }, 0)

   }

   /*
   * public methods
   * */

   onIFrameLoad(iframe?: HTMLIFrameElement) {
      this.iframeSrc = iframe?.src
   }

   exitCourse() {

      this._modalRef = this._modalService.open(this.modalTemplate, { backdrop: 'static' })

      let closeAndNavigate = () => {
         this._hybridWebviewInteropService.relayActivity(this._user.profile.contact, this._hybridWebviewInteropService.relayedActivityType?.moduleExit)
         this._modalRef.dismiss()
         this._router.navigate([this._coursesUri])
      }

      if (this.token) {

         this._contentRegistrationProgressesService.updateProgressRegistration(this.token)
            /* no matter what we get back - close and navigate */
            .finally(() => {
               closeAndNavigate()
            })

      } else {
         /* somehow no token? close and navigate */
         closeAndNavigate()
      }
   }
}
