import {
   HttpInterceptor,
   HttpHandler,
   HttpRequest,
   HttpEvent,
   HttpResponse,
   HttpErrorResponse,
   HttpStatusCode
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { tap, catchError } from 'rxjs/operators'
import { ToastrType } from '@appShared/services/toastr.service'
import { CommonService } from '@appShared/services/common.service'
import * as _ from 'lodash'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class HttpRequestInterceptor implements HttpInterceptor { //yeah
   constructor(
      private _commonService: CommonService,
      //private _appFacade: AppFacade,
      private _location: Location,
      private _router: Router
   ) { }
   intercept(
      req: HttpRequest<any>,
      next: HttpHandler
   ): Observable<HttpEvent<any>> {
      const authReq = req.clone({
         headers: req.headers
            .set('Cache-Control', 'no-cache, no-store, must-revalidate')
            .set('Pragma', 'no-cache')
            .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
            .set('If-Modified-Since', '0')
            .set(environment.serviceLayerClientIdHeaderName, environment.serviceLayerClientId)
            .set(environment.serviceLayerClientSecretHeaderName, environment.serviceLayerClientSecret),
         // Prevent caching in IE, in particular IE11.
         // See: https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
         setHeaders: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
         },
         withCredentials: true,
         // modify all request URLs that started with http to start with https
         url: req.url.replace('http://', 'https://')
      })

      return next.handle(authReq).pipe(
         tap(evt => {
            if (evt instanceof HttpResponse) {
               if (evt.body && evt.body.success) {
                  this._commonService.messageUser(
                     evt.body.success.message,
                     evt.body.success.title
                  )
               }

               // log api responses to client
               if (req.responseType === 'json') {
                  this._commonService.logApi(
                     req.urlWithParams,
                     req.method,
                     evt.body /* data */,
                     req.body /* payload */
                  )
               }

               // site is offline and they are non-admin
               if (
                  evt.status === 255 /*HttpStatusCode.serviceModeNotification*/
               ) {
                  //if not already on home page - reroute
                  if (!['', '/'].includes(this._location.path())) {
                     this._router.navigate(['/'])
                  }
               }

               if (evt.status === HttpStatusCode.Found
                  && req.url === environment.tprSSOEndpoint
               ) {
                  //TODO remove
                  //debugger
                  //window.location.href
               }
            }
         }),

         catchError((err: any) => {
            let errorMessage

            function _checkDuplicateAccountMessage(errorMessage) {
               if (req.url === '/api/core/accounts/profiles'
                  && req.method === 'POST'
                  && errorMessage.toLocaleLowerCase().includes('duplicate entry')
               ) {
                  // this is coming from a general "duplicate entry" for account create/update - specialize the message
                  return 'An account with this nickname/email already exist'
               }
               return errorMessage
            }

            if (err instanceof HttpErrorResponse) {
               let errorTitle

               try {
                  if (err.status === HttpStatusCode.Unauthorized) {
                     window.location.href = '/api/auth'
                     return
                  } else if (err.status === HttpStatusCode.PaymentRequired) {
                     return throwError(() => err)
                  }
                  if (err && (err.error || err.message)) {
                     const responseError = err.error

                     if (
                        err.status === HttpStatusCode.BadRequest ||
                        err.status === HttpStatusCode.NotFound ||
                        err.status === HttpStatusCode.MethodNotAllowed
                     ) {
                        if (_.isString(responseError)) {
                           if (
                              responseError.startsWith('<!DOCTYPE') ||
                              responseError.startsWith('<html')
                           ) {
                              errorMessage =
                                 'ERROR - An Unknown fatal error has occurred!'
                           } else {
                              errorMessage = _checkDuplicateAccountMessage(responseError)
                           }
                        } else if (
                           _.isObject(responseError) &&
                           responseError['message']
                        ) {
                           errorMessage = _checkDuplicateAccountMessage(responseError['message'])
                        }
                     } else if (
                        err.status === HttpStatusCode.InternalServerError
                     ) {
                        errorMessage = 'An unknown error has occurred'
                     }

                     errorMessage = errorMessage || 'An error occurred'
                     errorTitle = responseError && responseError.title
                  }
               } catch (e) {
                  errorMessage = e
               }

               this._commonService.messageUser(
                  errorMessage || 'An unknown error has occurred',
                  errorTitle || 'Error Occurred!',
                  ToastrType.error
               )
               this._commonService.setLoading(false)
               this._commonService.logError(
                  err,
                  req.method,
                  req.body /* payload */
               )
            }

            return throwError(() => errorMessage)
         })
      )
   }
}
