import { Injectable } from '@angular/core'
import {
   ActivatedRouteSnapshot,
   Router,
   RouterStateSnapshot
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, switchMap, take } from 'rxjs/operators'
import { AppFacade } from '@appShared/services/app.facade'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class RegistrationGuardService {
   constructor(
      private _appFacade: AppFacade,
      private _router: Router) { }

   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean> {
      // https://levelup.gitconnected.com/ngrx-and-angular-route-guards-a3fc3d5255f8
      return this._appFacade.user$.pipe(
         take(1),
         switchMap(user => {
            const requestedUrl = state.url
            const routes = environment.routes
            const token = route.queryParamMap.get('token') || ''

            if (!token) {
               //no token - they shouldn't be here
               this._router.navigate([routes.uri])

               return of(false)
            }

            // Requesting Step 1 - if logged in already - Go to step 2
            if (requestedUrl.startsWith('/sign-up') && !!user) {
               this._router.navigate([routes.registration.uri], {
                  queryParams: { 'token': token }
               })

               return of(false)

            } else if ((requestedUrl.startsWith('/complete-registration') || requestedUrl.startsWith('/registration'))
               && !!!user) {
               // Requesting Step 2 - if NOT logged in - go to Step 1
               this._router.navigate([routes.signUp.uri], {
                  queryParams: { 'token': token }
               })

               return of(false)
            }

            return of(true)
         }),
         catchError(() => of(false))
      )
   }
}
