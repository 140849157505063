import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'

@Injectable({ providedIn: 'root' })
export class ContentRegistrationsService {
   private _contentRegistrationsApi = '/api/content/content-registrations';

   // https://stackoverflow.com/a/71961946/1146117
   private _httpOptions = {}

   constructor(
      commonService: CommonService,
      private _httpClient: HttpClient
   ) {
      this._httpOptions = commonService.httpOptions()
   }

   getCourseRequestBody(token: any): Observable<any> {
      const url = `${this._contentRegistrationsApi}/prepare-launch-request-from-token?token=${token}`

      //return of({contextId: 213456})
      return this._httpClient.get<any>(url)

      //return new Promise((resolve) => resolve({ launchLink: token === 'advantage-prep' ? 'http://www.google.com' : 'http://salvationbygrace.org' }))
   }
}
