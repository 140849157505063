<div class="bg-site-secondary shadow">
   <header class="container d-flex flex-wrap align-items-center justify-content-between py-3 px-4">

      <a class="d-flex align-items-center mb-md-0 d-none d-md-inline-block"
         routerLink="/{{(user$|async)?.isUser ? routes.content.uri : 'member'}}">
         <!--TODO route-->
         <img src="/assets/images/logos/advantage-logo.png" class="logo" />
      </a>

      <a class="d-flex align-items-center mb-md-0 d-inline-block d-md-none"
         routerLink="/{{(user$|async)?.isUser ? routes.content.uri : 'member'}}">
         <!--TODO route-->
         <img src="/assets/images/logos/advantage-logo.png" class="logo-small" />
      </a>

      <div *ngIf="user$ | async as user"
           class="d-flex">
         <div class="dropdown flex-grow-1">
            <a href="#" class="d-block text-decoration-none dropdown-toggle" id="dropdownUserMenu" data-bs-toggle="dropdown" aria-expanded="false">
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
               </svg>
               <span class="ps-2 fw-bold d-none d-sm-inline-block">{{user.displayName}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end text-small" aria-labelledby="dropdownUserMenu">
               <li>
                  <a *ngIf="user.isUser"
                     class="dropdown-item fw-bold" routerLink="/{{routes.content.uri}}">Home</a>
                  <a *ngIf="!user.isUser"
                     class="dropdown-item fw-bold" routerLink="/{{routes.member.uri}}">Home</a>
               </li>
               <li *ngIf="user.isUser">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.uri}}">{{routes.content.title}}</a>
               </li>
               <li *ngIf="user.profile.info.isAccountOwner">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.member.account.uri}}">{{routes.member.account.title}}</a>
                  <!--<span *ngIf="!user.profile.info.isAccountOwner"
               (click)="comingSoon('Awards')"
               class="dropdown-item" >Awards</span>-->
               </li>
               <li><hr class="dropdown-divider"></li>
               <li><span class="dropdown-item pointable" (click)="logout()">Sign out</span></li>
            </ul>
         </div>
      </div>


      <!--<form class="d-flex align-items-center col-md-3 mb-2 mb-md-0 d-none d-sm-block">
      <input type="search" class="form-control" placeholder="Search..." aria-label="Search">
   </form>-->
      <!--<div *ngIf="!(isDotNetInteroperable$|async)"
        class="title nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 fw-bold text-uppercase">
      {{(headerTitle$|async)|| leadSection?.title}}
   </div>-->


   </header>
</div>
