<app-public-header *ngIf="!(user$ | async)"></app-public-header>

<app-internal-header *ngIf="(user$ | async) && !(isDotNetInteroperable$|async)"
                     [leadSection]="leadSection" class="flex-grow-0 flex-shrink-1 flex-basis-auto"></app-internal-header>

<section class="flex-grow-1 flex-shrink-0">
   <router-outlet></router-outlet>
</section>

<app-site-footer *ngIf="!(isDotNetInteroperable$|async)" class="flex-grow-0 flex-shrink-1"></app-site-footer>
