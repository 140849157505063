import { Injectable } from '@angular/core'
import {
   ActivatedRouteSnapshot,
   Router
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, switchMap, take } from 'rxjs/operators'
import { AppFacade } from '@appShared/services/app.facade'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class PublicOnlyGuardService {
   constructor(private _appFacade: AppFacade, private _router: Router) { }

   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
      // https://levelup.gitconnected.com/ngrx-and-angular-route-guards-a3fc3d5255f8
      return this._appFacade.user$.pipe(
         take(1),
         switchMap(user => {
            // if already logged in - then send to "content" section
            if (user) {
               this._router.navigate([`${environment.routes.content.uri}`])
               return of(false)
            }

            return of(true)
         }),
         catchError(() => of(false))
      )
   }
}
