<div class="bg-site-secondary shadow">
   <header class="container d-flex flex-wrap justify-content-between align-items-center py-3 px-4 pb-2">
      <a class="mb-md-0 ms-3 me-md-auto text-dark text-decoration-none brand"
         [routerLink]="['/']">
         <img src="/assets/images/logos/SWA-logo.svg" class="logo" />
      </a>

      <a class="mb-md-0 text-dark text-decoration-none brand"
         href="https://www.princetonreview.com/" target="_blank">
         <img src="/assets/images/logos/princeton-logo.png" class="princeton" />
      </a>

   </header>
</div>
