import { mapToCanActivate, Routes } from '@angular/router';
import {
   AuthGuardService,
   AuthGuardUserService,
   RegistrationGuardService,
   PublicOnlyGuardService
} from '@appShared/services';
import { IFramePlayerComponent } from '@appShared/components/iframe-player/iframe-player.component'
import { LandingComponent } from '@appContainers/landing/landing.component'
import { PageNotFoundComponent } from '@appContainers/page-not-found.component'
import { ShellComponent } from '@appContainers/shell/shell.component'
import { environment } from '@appEnvironments/environment';

export const APP_ROUTES: Routes = [
   {
      path: '',
      component: ShellComponent,
      children: [
         {
            path: '',
            canActivate: mapToCanActivate([PublicOnlyGuardService]),
            component: LandingComponent
         },
         //...CONTENT_ROUTES, /* If NOT lazy-loading */
         {
            path: environment.routes.content.baseUri,
            canActivate: mapToCanActivate([AuthGuardUserService]),
            loadChildren: () =>
               import('./content/content.routes').then(mod => mod.CONTENT_ROUTES)
         },
         {
            path: environment.playerUri,
            canActivate: mapToCanActivate([AuthGuardUserService]),
            component: IFramePlayerComponent
         },
         //...MEMBER_ROUTES, /* If NOT lazy-loading */
         {
            path: environment.routes.member.baseUri,
            canActivate: mapToCanActivate([AuthGuardService]),
            loadChildren: () =>
               import('./member/member.routes').then(mod => mod.MEMBER_ROUTES)
         },
         //...REGISTRATION_LANDING_ROUTES, /* If NOT lazy-loading */
         {
            path: environment.routes.registrationLanding.baseUri,
            loadComponent: () =>
               import('./containers/registration-landing/registration-landing.component').then(
                  mod => mod.RegistrationLandingComponent
               )
         },
         {
            path: environment.routes.registration.baseUri,
            canActivate: mapToCanActivate([RegistrationGuardService]),
            loadComponent: () => import('./containers/registration/registration.component').then(
               mod => mod.RegistrationComponent
            )
         },
         {
            /* keeping this path in case old email still have /complete-registration link */
            path: environment.routes.completeRegistration.baseUri,
            canActivate: mapToCanActivate([RegistrationGuardService]),
            loadComponent: () => import('./containers/registration/registration.component').then(
               mod => mod.RegistrationComponent
            )
         },
         {
            path: environment.routes.signUp.baseUri,
            canActivate: mapToCanActivate([RegistrationGuardService]),
            loadComponent: () => import('./containers/sign-up/sign-up.component').then(
               mod => mod.SignUpComponent
            )
         },
         {
            path: '404',
            component: PageNotFoundComponent
         },
         { path: '**', redirectTo: '404', pathMatch: 'full' }
      ]
   }
]
