<section class="bg-site-secondary shadow site-footer text-site p-3">

   <div class="container">

      <div class="text-center">Not Affiliated with Princeton University.</div>

      <div class="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between px-md-4 fw-bold"
           [hidden]="hideFooter">
         <!--<span class="ms-2 text-site copy">Copyright &copy; {{currentYear}} - College Entrance Advantage</span>-->

         <ul class="nav ms-2 d-flex align-items-center">
            <li class="nav-item">
               <a href="http://www.southwestern.com/"
                  class="nav-link text-site"
                  target="_blank">About Us</a>
            </li>
            <li class="nav-item">|</li>
            <li class="nav-item">
               <a [href]="contactUsUri"
                  class="nav-link text-site"
                  target="_blank">Contact Us</a>
            </li>
            <li class="nav-item">|</li>
            <li class="nav-item">
               <a href="https://southwestern.com/terms-of-use/"
                  class="nav-link text-site"
                  target="_blank">Terms of Use</a>
            </li>
         </ul>

         <ul class="nav me-2 d-flex align-items-center social-nav gap-2">
            <li class="nav-item">
               <a target="_blank" href="https://www.facebook.com/SouthwesternAdvantage/"><img src="/assets/images/social/dark-blue/facebook.png" class="social-link"></a>
            </li>
            <li class="nav-item">
               <a target="_blank" href="https://www.youtube.com/user/southwesternadv"><img src="/assets/images/social/dark-blue/youtube.png" class="social-link"></a>
            </li>
            <li class="nav-item">
               <a target="_blank" href="https://twitter.com/southwestern"><img src="/assets/images/social/dark-blue/twitter.png" class="social-link"></a>
            </li>
            <li class="nav-item">
               <a target="_blank" href="https://www.instagram.com/southwesternadvantage/"><img src="/assets/images/social/dark-blue/instagram.png" class="social-link"></a>
            </li>
            <li class="nav-item">
               <a target="_blank" href="https://www.pinterest.com/southwesternadv/boards/"><img src="/assets/images/social/dark-blue/pinterest.png" class="social-link"></a>
            </li>
            <li class="nav-item">
               <a target="_blank" href="https://www.linkedin.com/company/7447/"><img src="/assets/images/social/dark-blue/linked-in.png" class="social-link"></a>
            </li>
            <li class="nav-item">
               <a target="_blank" href="https://southwesternadvantage.com/products-and-services-blog/"><img src="/assets/images/social/dark-blue/blogger.png" class="social-link"></a>
            </li>
         </ul>
      </div>

   </div>

</section>
