import { Injectable } from '@angular/core'
import { Observable, catchError, combineLatestWith, of, switchMap, take } from 'rxjs'
import { AccountsService } from '@appAccount/shared/services/accounts.service'
import { AuthService } from '@appShared/services/auth.service'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class AppConfigProvider {

   constructor(

      private _authService: AuthService,
      private _accountsService: AccountsService
      //hybridWebviewInteropService: HybridWebviewInteropService
   ) { }

   init(): Observable<boolean> {

      this._prepareRouteUris()

      return this._authService.initializeSiteConfig().pipe(
         combineLatestWith(this._accountsService.refreshCurrentUser())
      ).pipe(
         take(1),
         switchMap(([siteConfig, user]) => {

            return of(true)
         }),
         catchError(error =>

            of(false)
         )
      )
   }

   private _prepareRouteUris(): void {

      /* iterative method to loop over route objects
         to create usuable uris for ease of routing
         and no need to concatenate routes in code
      */

      let setConditionalUri = (parentUri?: any, uri?: any): string => {
         return parentUri
            ? uri
               ? `${parentUri}/${uri}`
               : parentUri
            : uri
               ? uri
               : ''
      }

      let setUrl = (obj: any, parentBaseUri?: any): void => {
         let baseUri = obj.baseUri

         Object.entries(obj)
            .forEach(([key, value]) => {
               if (typeof value === "object") {

                  // build "baseUri" to pass to children objects
                  baseUri = setConditionalUri(parentBaseUri, obj.baseUri)

                  setUrl(value, baseUri)

               } else if (key === 'uri') {

                  // set uri based on parent routes
                  obj[key] = setConditionalUri(parentBaseUri, value)
               }
            })
      }

      let routes = environment.routes

      setUrl(routes)
   }

}
