import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { lastValueFrom, Observable, of, switchMap, take } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { IPartition } from '@appShared/interfaces/[Model-based]/partition.interface'
import { IContainer } from '@appShared/interfaces/[Model-based]/container.interface'
import { ILessonContent } from '@appShared/interfaces/[Model-based]/lesson-content.interface'
import { ComponentType_ } from '@appShared/services/lookup/[CodeGen]/component-type.domain'
import { ToastrType } from '@appShared/services/toastr.service'
import { environment } from '@appEnvironments/environment'


export interface IAreaData {
   area: IContainer
   moduleList: IContainer[]
}

export interface IModuleData {
   module: IContainer
   lessonList: IContainer[]
}

@Injectable({
   providedIn: 'root'
})
export class ContainersService {
   private _containersApi = '/api/content/containers';

   constructor(
      private _http: HttpClient,
      private _commonService: CommonService,
      private _router: Router
   ) { }

   async getPartitions(): Promise<IPartition[]> {
      const url = `${this._containersApi}/partitions/default`

      const request$ = this._http.get<IPartition[]>(url).pipe(take(1))

      return await lastValueFrom<IPartition[]>(request$)
   }

   getAreas(subscriptionProductPartionContainerId?: number): Observable<IContainer[]> {
      const url = subscriptionProductPartionContainerId
         ? `${this._containersApi}/partitions/${subscriptionProductPartionContainerId}/areas`
         : `${this._containersApi}/areas/default`

      return this._http.get<IContainer[]>(url)
   }

   getArea(areaId: number): Observable<IContainer> {
      const url = `${this._containersApi}/areas/${areaId}`

      return this._http.get<IContainer>(url)
   }

   async getContainers(ids: number[]): Promise<IContainer[]> {
      const idsParam = ids.map(id => `&ids=${id}`)
      const url = `${this._containersApi}/set?${idsParam.join('')}`

      const request$ = this._http.get<IContainer[]>(url).pipe(take(1))

      return await lastValueFrom<IContainer[]>(request$)
   }

   async getSubjects(token?: string): Promise<IContainer[]> {
      const url = `${this._containersApi}/subjects/${token}`

      const request$ = this._http.get<IContainer[]>(url).pipe(take(1))

      return await lastValueFrom<IContainer[]>(request$)
   }

   async getTopics(token?: string): Promise<IContainer[]> {
      const url = `${this._containersApi}/topics/${token}`

      const request$ = this._http.get<IContainer[]>(url).pipe(take(1))

      return await lastValueFrom<IContainer[]>(request$)
   }

   getModules(areaId?: number): Observable<IContainer[]> {
      const url = `${this._containersApi}/areas/${areaId}/modules`

      return this._http.get<IContainer[]>(url)
   }

   getModulesForAreaComponent(componentTypeCode?: ComponentType_): Observable<IContainer[]> {
      if (!componentTypeCode) return of([] as IContainer[])

      const url = `${this._containersApi}/areas/${componentTypeCode}/modules-for-component`

      return this._http.get<IContainer[]>(url)
   }

   async getNextInProgressForAreaComponent(componentTypeCode?: ComponentType_): Promise<IContainer> {
      if (!componentTypeCode) return new Promise((resolve, reject) => reject('No component Type passed'));


      const request$ = this.getModulesForAreaComponent(componentTypeCode)
         .pipe(
            take(1),
            switchMap((modules) => {
               //return first module not complete and not locked
               const inProgressModule = modules.find(module => !module.info.isComplete && !module.info.isLocked)

               return of(inProgressModule)
            })
         )

      return await lastValueFrom<IContainer>(request$)
   }

   goToNextInProgressForAreaComponent(componentTypeCode?: ComponentType_): void {
      this.getNextInProgressForAreaComponent(componentTypeCode)
         .then(module => {
            if (!module?.info.isLocked) {
               const token = module.info.encodedToken
               const queryParams = token ? { queryParams: { token } } : {}
               this._router.navigate([`/${environment.playerUri}`], queryParams)
            } else {
               this._commonService.messageUser('This course is currently locked', null, ToastrType.info)
            }
         })
   }

   goToNextInProgressCourse(): void {
      this.goToNextInProgressForAreaComponent(ComponentType_.CourseLibrary)
   }

   getPartitionsForTenant(): Observable<IContainer[]> {

      const url = `${this._containersApi}/partitions/tenant`

      return this._http.get<IContainer[]>(url)

      //TODO get me something real
      //return of([
      //   { id: 1234, qualifiedHostedContentToken: 'advantage-prep', info: { hostedContentToken: 'advantage-prep' } },
      //   { id: 4567, qualifiedHostedContentToken: 'college-entrance-advantage', info: { hostedContentToken: 'college-entrance-advantage' } }
      //] as IContainer[])
   }

   getModule(moduleId?: number): Observable<IContainer> {
      const url = `${this._containersApi}/modules/${moduleId}`

      return this._http.get<IContainer>(url)
   }

   getLessons(moduleId?: number): Observable<IContainer[]> {
      const url = `${this._containersApi}/modules/${moduleId}/lessons`;

      return this._http.get<IContainer[]>(url)
   }

   getLesson(lessonId?: number): Observable<IContainer> {
      const url = `${this._containersApi}/lessons/${lessonId}`;

      return this._http.get<IContainer>(url)
   }

   async getLessonContents(lessonId?: number): Promise<ILessonContent[]> {
      const url = `${this._containersApi}/lessons/${lessonId}/lesson-contents`

      const request$ = this._http.get<ILessonContent[]>(url).pipe(take(1))

      return await lastValueFrom<ILessonContent[]>(request$)
   }
}
