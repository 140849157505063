import { createSelector } from '@ngrx/store'
import { getAppFeatureState } from '@appState/reducers/app.reducer'
import { getLocaleCurrencyName } from '@angular/common'

// export const getItemState = createSelector(
//  fromFeature.getProfileFeatureState,
//  (state: fromFeature.ProductsAdminState) => state.items
// );

export const getSiteConfig = createSelector(
   getAppFeatureState,
   state => state?.config
)

export const getCurrentUser = createSelector(getAppFeatureState, state => {
   return state?.user
})

//export const getHasVerifiedSSN = createSelector(getAppFeatureState, state => {
//  return state.hasVerifiedSSN;
//});

//export const getStockPlanUri = createSelector(
//  getSiteConfig,
//  state => state.stockPlanUri
//);

export const getSupportEmailAddress = createSelector(
   getSiteConfig,
   state => state?.supportEmailAddress
)

//export const getIsAdminOnlyMode = createSelector(getSiteConfig, state => {
//  return state.isAdminOnlyMode;
//});

//export const getAreShareholdersAccessible = createSelector(
//  getSiteConfig,
//  state => {
//    const user = state.user;
//    const isAdminOnlyMode = state.isAdminOnlyMode;

//    return (
//      user.isAdmin ||
//      (!isAdminOnlyMode &&
//        (user.isOfficer || user.hasSubordinates))
//    );
//  }
//);
